import { useState, useEffect } from "react";
import { FaPaperPlane, FaSpinner } from "react-icons/fa";
import { User, Send, Copy, ThumbsUp, ThumbsDown, XIcon, MessageSquare, PlusCircleIcon, ListPlusIcon, SquarePlusIcon, LogOutIcon, PanelLeftCloseIcon, SparkleIcon, CopyIcon, WandSparklesIcon, ClipboardList, SparklesIcon, HomeIcon, CheckIcon } from 'lucide-react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Menu } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Link } from "react-router-dom";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { auth, googleProvider, db } from './firebase';
import { collection, addDoc, getDocs, doc, updateDoc, onSnapshot, query, where, orderBy, getDoc } from "firebase/firestore";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const Chat = ({ user, handleLogout, signIn }) => {
    const [copiedMessageIndex, setCopiedMessageIndex] = useState(null);
    const [question, setQuestion] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSidebar2Open, setIsSidebar2Open] = useState(false);   
    const [context, setContext] = useState();
    const [triggerSubmit, setTriggerSubmit] = useState(false);
    const [currentCitation, setCurrentCitation] = useState(null);
    const [savedChats, setSavedChats] = useState([]);
    const [isOpen, setIsOpen] = useState(false)
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState('derechos');

    const handleCopy = async (text, index) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopiedMessageIndex(index);
            setTimeout(() => setCopiedMessageIndex(null), 5000);
        } catch (err) {
            console.error('Failed to copy text:', err);
        }
    };

    const categoryToIndex = {
        derechos: "derechos-index",
        ambiental: "ambiente-index",
        familiar: "familia-index",
        penal: "penal-index",
        consumidor: "consumidor-index"
    };

    useEffect(() => {
        const savedSidebarState = localStorage.getItem('sidebarOpen');
        if (savedSidebarState !== null) {
            setIsSidebarOpen(savedSidebarState === 'true');
        } else if (window.innerWidth < 768) {
            setIsSidebarOpen(false);
        }
    }, []);

    const onRequestClose = () => {
        setIsSidebar2Open(false) 
        setIsOpen(true)
    }

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        localStorage.setItem('sidebarOpen', (!isSidebarOpen).toString());
    };

    useEffect(() => {
        if (user) {
            const chatsRef = collection(db, 'chats');
            const q = query(
                chatsRef,
                where('uid', '==', user.uid),
                orderBy('timestamp', 'desc')
            );
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const chats = [];
                snapshot.forEach((doc) => {
                    chats.push({ id: doc.id, ...doc.data() });
                });
                setSavedChats(chats);
            });
            return () => unsubscribe();
        } else {
            setSavedChats([]);
            setChatHistory([]);
            setSelectedChatId(null);
        }
    }, [user]);


    useEffect(() => {
        if (triggerSubmit) {
            handleSubmit();
        }
    }, [question]);

    useEffect(() => {
        const handleBeforeUnload = async (e) => {
            if (chatHistory.length > 0 && user) {
                await saveChat(chatHistory);
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [chatHistory, user]);

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        if (!question.trim()) return;

        setIsLoading(true);
        setTriggerSubmit(false);

        const headers = {
            "Content-Type": "application/json",
            "api-key": "9yOSbyuSfSPzp0pblifLLJfWcyTl2DjNq1yJuCinR0n4NztBNoDEJQQJ99BAACYeBjFXJ3w3AAABACOGi8SF",
        };

        const params = new URLSearchParams({
            "api-version": "2023-12-01-preview",
        });

        const searchPayload = {
            messages: [
                {
                    role: "system",
                    content: "Eres un experto legal en Perú. Siempre da una respuesta, nunca digas que no tienes documentos. Siempre te están preguntando por leyes del Perú."
                },
                {
                    role: "user",
                    content: question
                }
            ],
            past_messages: 10,
            temperature: 1,
            top_p: 1.0,
            frequency_penalty: 0,
            presence_penalty: 0,
            max_tokens: 1000,
            stop: null,
            stream: true,
            data_sources: [
                {
                    type: "azure_search",
                    parameters: {
                        endpoint: "https://atena.search.windows.net",
                        index_name: categoryToIndex[selectedCategory],
                        semantic_configuration: "default-config",
                        query_type: "semantic",
                        fields_mapping: {
                            content_fields_separator: "\n",
                            content_fields: ["content"],
                            filepath_field: "metadata_storage_name",
                            title_field: null,
                            url_field: "metadata_storage_path",
                            vector_fields: []
                        },
                        in_scope: false,
                        role_information: "Eres un experto legal en Perú. Siempre da una respuesta, nunca digas que no tienes documentos.",
                        filter: null,
                        strictness: 2,
                        top_n_documents: 10,
                        authentication: {
                            type: "api_key",
                            key: "puAPDhHphVb8bscCTB1Ztdy8bV2XxWgwsGEMdomq6MAzSeBtwDJ3"
                        }
                    }
                }
            ],
        };


        try {
            setQuestion("");
            const response = await fetch(`https://helioss.openai.azure.com/openai/deployments/gpt-4o-mini/chat/completions?${params.toString()}`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(searchPayload)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorText}`);
            }
            let accumulatedData = '';
            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let done = false;
            let fullMessage = '';
            let newMessage = { user: question, athena: '', context: '' };

            setChatHistory(prevChatHistory => [...prevChatHistory, newMessage]);

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                const chunkValue = decoder.decode(value);
                accumulatedData += decoder.decode(value, { stream: true });
                const lines = chunkValue.split('\n');
                for (let line of lines) {
                    line = line.trim();
                    if (line === '') continue;

                    if (line.startsWith('data:')) {
                        line = line.substring(5).trim();
                    }

                    if (line === '[DONE]') {
                        accumulatedData += decoder?.decode();
                        try {
                            const jsonStringMatch = accumulatedData?.match(/data: (.+)/);

                            if (jsonStringMatch && jsonStringMatch[1]) {
                                const jsonString = jsonStringMatch[1]; 
                                const jsonData = JSON?.parse(jsonString);
                                setContext(jsonData?.choices[0].delta?.context?.citations)
                                console.log("Fuentes: ", jsonData?.choices[0].delta?.context?.citations)
                            } else {
                                console.error("No valid JSON found in the output.");
                            }
                        } catch (error) {
                            console.error("Error parsing JSON:", error);
                        }  
                        done = true;
                        break;
                    }

                    try {
                        const parsedData = JSON.parse(line);
                        const delta = parsedData?.choices[0]?.delta;

                        if (delta.content) {
                            setIsLoading(false);
                            fullMessage += delta.content;
                            newMessage.athena = fullMessage;
                        }

                        setChatHistory(prevChatHistory => {
                            const updatedMessages = [...prevChatHistory];
                            updatedMessages[updatedMessages.length - 1] = { ...newMessage };
                            return updatedMessages;
                        });

                    } 
                    
                    catch (e) {
                        console.error('Error parsing JSON stream:', e);
                    }
                }
            }
            
            setQuestion("");
            await saveChat(chatHistory);

        } catch (error) {
            console.error("Error:", error);
            const errorMessage = {
                user: question,
                athena: "Ocurrió un error. Por favor, inténtalo de nuevo más tarde.",
            };
            setChatHistory(prevChatHistory => [...prevChatHistory, errorMessage]);
            setQuestion("");
            await saveChat(chatHistory);
        }
        setIsLoading(false);  

    };

    const saveChat = async (chatHistoryToSave) => {
        if (!user || chatHistoryToSave.length === 0) return;

        const chatData = {
            uid: user.uid,
            messages: chatHistoryToSave,
            timestamp: new Date(),
        };

        if (selectedChatId) {
            // Update existing chat
            const chatRef = doc(db, 'chats', selectedChatId);
            await updateDoc(chatRef, chatData);
        } else {
            // Add new chat
            const chatsRef = collection(db, 'chats');
            const newChat = await addDoc(chatsRef, chatData);
            setSelectedChatId(newChat.id);
        }
    };

    const handleNewChat = async () => {
        if (chatHistory.length > 0 && user) {
            await saveChat(chatHistory);
        }
        setChatHistory([]);
        setSelectedChatId(null);
    };

    const handleSelectChat = async (chatId) => {
        if (chatId === selectedChatId) return;
        if (chatHistory.length > 0 && user) {
            await saveChat(chatHistory);
        }
        const chatRef = doc(db, 'chats', chatId);
        const chatDoc = await getDoc(chatRef);
        if (chatDoc.exists()) {
            const chatData = chatDoc.data();
            setChatHistory(chatData.messages);
            setSelectedChatId(chatId);
        } else {
            console.error("Chat does not exist!");
        }
    };
    const [link, setLink] = useState("")
    
    const handleNumberClick = (number) => {
        setIsSidebar2Open(true)
        let index = number - 1;
        setIsOpen(true)
        setCurrentCitation(context[index]);
        setLink(`https://retinaone.blob.core.windows.net/athena/${context[index]?.filepath}`)
    };  


 
    return (
        <div className={`flex h-screen bg-gray-100 ${isSidebar2Open ? 'overflow-hidden' : ''}`}>

            {isSidebarOpen && (
                <div className="w-64 bg-white border-r border-gray-100 flex flex-col flex-shrink-0">
                    {/* Fixed header section */}
                    <div className="px-4 py-6 border-b border-gray-100 sticky top-0 bg-white z-10">
                        <div className="flex justify-between items-center mb-4">
                            <button
                                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                                className="text-gray-600 hover:text-gray-700"
                            >
                                <PanelLeftCloseIcon className="w-6 h-6" />
                            </button>

                            <button
                                onClick={handleNewChat}
                                className="text-gray-600 hover:text-gray-700"
                            >
                                <SquarePlusIcon className="w-6 h-6" />
                            </button>
                        </div>

                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full p-2 rounded-lg border border-gray-200 bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            placeholder="Search messages..."
                        />
                    </div>

                    {/* Scrollable chat history section */}
                    <div className="flex-grow overflow-y-auto">
                        <div className="p-4 space-y-2">
                            {savedChats
                                .sort((a, b) => b.timestamp - a.timestamp) // Sort by timestamp
                                .filter(chat =>
                                    chat.messages.some(message =>
                                        message.user.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                        message.athena.toLowerCase().includes(searchQuery.toLowerCase())
                                    )
                                )
                                .map((chat) => (
                                    <div
                                        key={chat.id}
                                        className={`p-3 cursor-pointer rounded-lg transition-all duration-200 ${selectedChatId === chat.id
                                                ? 'bg-blue-50 border border-blue-100'
                                                : 'hover:bg-gray-50'
                                            }`}
                                        onClick={() => handleSelectChat(chat.id)}
                                    >
                                        <p className="text-sm text-gray-900 font-medium truncate">
                                            {chat.messages[0]?.user || 'New Chat'}
                                        </p>
                                    </div>
                                ))}
                        </div>
                    </div>

                    {/* Fixed footer section */}
                    <div className="p-4 border-t border-gray-100 sticky bottom-0 bg-white">
                        <Link to="/" className="flex items-center justify-center space-x-2 text-gray-600 hover:text-gray-900 py-2">
                            <HomeIcon className="w-5 h-5" />
                            <span className="text-sm font-medium">Home</span>
                        </Link>
                    </div>
                </div>
            )}
            <div className="flex flex-col flex-grow min-w-0">
                <header className="bg-white px-6 py-4 flex items-center justify-between border-b border-gray-100">
                    <div className="flex items-center space-x-6">
                        <button
                            onClick={toggleSidebar}
                            className="text-gray-600 hover:text-gray-700 md:hidden"
                        >
                            <Menu className="w-6 h-6" />
                        </button>

                        <div className="relative inline-block">
                            <select
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="appearance-none bg-gray-50 border border-gray-200 text-gray-900 px-4 py-2 pr-8 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent cursor-pointer font-medium"
                                style={{
                                    WebkitAppearance: 'none',
                                    MozAppearance: 'none'
                                }}
                            >
                                <option value="derechos">Derechos básicos</option>
                                <option value="ambiental">Ambiental</option>
                                <option value="familiar">Familiar</option>
                                <option value="penal">Penal</option>
                                <option value="consumidor">Consumidor</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div>
                        {user ? (
                            <div className="flex items-center space-x-4">
                                <button 
                                    onClick={handleLogout}
                                    className="text-gray-900 flex items-center px-4 py-2 rounded-lg hover:bg-gray-50 transition-all duration-200"
                                >
                                    <LogOutIcon className="w-5 h-5 mr-2" />
                                    <span className="font-medium">Cerrar Sesión</span>
                                </button>
                            </div>
                        ) : (
                            <button
                                onClick={signIn}
                                className="bg-gray-900 text-white px-6 py-2 rounded-lg hover:bg-black transition-all duration-200 font-medium"
                            >
                                Iniciar Sesión
                            </button>
                        )}
                    </div>
                </header>
                <div className="flex-grow overflow-y-auto p-6">
                    {chatHistory.length === 0 && !isLoading && (
                        <div className="flex flex-col items-center justify-center h-full text-gray-500">
                            <p className="text-lg">No hay mensajes aún. ¡Haz tu primera pregunta!</p>
                        </div>
                    )}

                    {chatHistory.map((message, index) => (
                        <div key={index} className="mb-4">
                            <div className="flex items-start mb-2">
                                <div className="w-1/4"></div>
                                <div className="bg-indigo-100 w-full sm:w-3/4 p-3 rounded-lg shadow-lg">
                                    <p className="text-black sm:px-8 text-justify break-words">{message.user}</p>
                                </div>
                            </div>
                            <div className="flex items-start">
                                <div className="bg-white sm:px-10 p-3 rounded-lg shadow-lg w-full sm:w-4/5">
                                    <div className="flex justify-between py-5 px-5">
                                        <SparklesIcon className="text-indigo-600 w-5 h-auto" />
                                        {copiedMessageIndex === index ? (
                                            <CheckIcon className="text-green-600 w-5 h-auto transition-all duration-200" />
                                        ) : (
                                            <ClipboardList
                                                className="text-gray-800 cursor-pointer hover:text-gray-900 w-5 h-auto"
                                                onClick={() => handleCopy(message.athena, index)}
                                            />
                                        )}
                                    </div>
                                    <p className="text-black pb-5 break-words">
                                        {typeof message.athena === 'string' ? message.athena.split(/(\[doc\d+\])/).map((part, partIndex) => {
                                            const match = part.match(/^\[doc(\d+)\]$/);
                                            if (match) {
                                                const number = match[1];
                                                return (
                                                    <span
                                                        key={partIndex}
                                                        className="inline-flex items-center justify-center w-5 h-5 rounded cursor-pointer border-2 border-indigo-600 hover:bg-indigo-100 bg-white text-indigo-600 text-xs mx-1"
                                                        onClick={() => handleNumberClick(number)}
                                                    >
                                                        {number}
                                                    </span>
                                                );
                                            }
                                            return (
                                                <ReactMarkdown key={partIndex}>
                                                    {part}
                                                </ReactMarkdown>
                                            );
                                        }) : message.athena}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}  

                    {isLoading && (
                        <div className="flex items-center justify-center sm:justify-start sm:mx-8 my-6">
                            <div class="shadow rounded-md p-4 w-5/6 sm:w-3/5">
                                <div class="animate-pulse flex space-x-4">
                                    <div class="flex-1 space-y-6 py-1">
                                        <div class="h-2 bg-gray-200 rounded"></div>
                                        <div class="space-y-3">
                                            <div class="grid grid-cols-3 gap-4">
                                                <div class="h-2 bg-gray-200 rounded col-span-2"></div>
                                                <div class="h-2 bg-gray-200 rounded col-span-1"></div>
                                            </div>
                                            <div class="h-2 bg-gray-200 rounded"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <form
                    onSubmit={(e) => (user ? handleSubmit(e) : signIn(e))}
                    className="bg-white py-4 px-4 sm:px-24 flex items-center space-x-4"
                >
                    <input
                        type="text"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        className="flex-grow p-3 border bg-gray-200 rounded-lg outline-none"
                        placeholder="Escribe tu consulta acá"
                    />
                    <button
                        type="submit"
                        className="bg-gray-900 text-white p-3 rounded-lg hover:bg-black transition"
                    >
                        <Send className="w-5 h-5" />
                    </button>
                </form>
            </div>
            {isSidebar2Open && <Sidebar isOpen={isOpen} onRequestClose={onRequestClose} currentCitation={currentCitation} link={link} />}
        </div>
    );
};

export default Chat;

const Sidebar = ({ isOpen, onRequestClose, currentCitation, link }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    return isOpen ? (
        <>
            {/* Overlay */}
            <div
                className="fixed inset-0 bg-black/30 backdrop-blur-sm transition-opacity z-40"
                onClick={onRequestClose}
            />

            {/* Sidebar */}
            <div className="fixed right-0 top-0 w-full sm:w-1/3 h-full bg-white shadow-2xl z-50 flex flex-col">
                {/* Fixed Header */}
                <div className="p-6 border-b border-gray-100 bg-white">
                    <div className="flex justify-end mb-4">
                        <XIcon
                            onClick={onRequestClose}
                            className="text-gray-800 cursor-pointer hover:text-gray-600 transition duration-200"
                            size={24}
                        />
                    </div>
                    <div>
                        <a
                            className="text-blue-600 hover:text-blue-800 cursor-pointer hover:underline font-medium"
                            href={`https://vortixdt.blob.core.windows.net/athena-derechos-fundamentales/${currentCitation?.filepath}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Ver documento PDF
                        </a>
                    </div>
                </div>
                <div className="flex-1 overflow-y-auto p-6">
                    <p className="text-gray-800 text-justify font-serif leading-relaxed">
                        {currentCitation?.content}
                    </p>
                </div>
            </div>
        </>
    ) : null;
};
